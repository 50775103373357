export type Exam = keyof typeof Exams
export type Language = 'fi' | 'sv'

export enum Exams {
  A = 'A',
  A5 = 'A5',
  BA = 'BA',
  BB = 'BB',
  BI = 'BI',
  CA = 'CA',
  CB = 'CB',
  DC = 'DC',
  EA = 'EA',
  EC = 'EC',
  ET = 'ET',
  FA = 'FA',
  FC = 'FC',
  FF = 'FF',
  FY = 'FY',
  GC = 'GC',
  GE = 'GE',
  HI = 'HI',
  I = 'I',
  IC = 'IC',
  KE = 'KE',
  L1 = 'L1',
  L7 = 'L7',
  M = 'M',
  N = 'N',
  O = 'O',
  O5 = 'O5',
  PA = 'PA',
  PC = 'PC',
  PS = 'PS',
  QC = 'QC',
  SA = 'SA',
  SC = 'SC',
  TC = 'TC',
  TE = 'TE',
  UE = 'UE',
  UO = 'UO',
  VA = 'VA',
  VC = 'VC',
  W = 'W',
  YH = 'YH',
  Z = 'Z',
}

export const disallowedExamsForLanguage: { [key in Language]: Exam[] } = {
  fi: [Exams.CA, Exams.CB, Exams.O5],
  sv: [Exams.BA, Exams.BB, Exams.A5],
}

export interface ExamGroup {
  code: string
  exams: Exam[]
}

export const examsByGroup: ExamGroup[] = [
  {
    code: 'reals',
    exams: [
      Exams.BI,
      Exams.ET,
      Exams.FF,
      Exams.FY,
      Exams.HI,
      Exams.KE,
      Exams.GE,
      Exams.PS,
      Exams.TE,
      Exams.UE,
      Exams.UO,
      Exams.YH,
    ],
  },
  {
    code: 'mathematics',
    exams: [Exams.M, Exams.N],
  },
  {
    code: 'mother-tongue',
    exams: [Exams.A, Exams.O, Exams.I, Exams.W, Exams.Z, Exams.A5, Exams.O5],
  },
  {
    code: 'second-domestic',
    exams: [Exams.CA, Exams.CB, Exams.BA, Exams.BB],
  },
  {
    code: 'foreign-languages',
    exams: [
      Exams.EA,
      Exams.EC,
      Exams.FA,
      Exams.FC,
      Exams.GC,
      Exams.L1,
      Exams.L7,
      Exams.PA,
      Exams.PC,
      Exams.SA,
      Exams.SC,
      Exams.TC,
      Exams.IC,
      Exams.DC,
      Exams.QC,
      Exams.VA,
      Exams.VC,
    ],
  },
]
