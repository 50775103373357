import React from 'react'
import { Language } from '../../exam'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory, useLocation, useRouteMatch } from 'react-router-dom'

export const LanguageSelectButton: React.FC<{ language: Language }> = ({ language }) => {
  const { i18n } = useTranslation()
  const history = useHistory()
  const { path, params } = useRouteMatch()
  const { search } = useLocation()

  function selectLanguage(language: Language) {
    history.replace(generatePath(path, { ...params, language }) + search)
  }

  return (
    <button
      className={
        'main__language-select-button' + (language === i18n.language ? ' main__language-select-button--selected' : '')
      }
      onClick={() => selectLanguage(language)}
    >
      {language.toUpperCase()}
    </button>
  )
}
