import React, { useEffect, useState } from 'react'
import { Exam, ExamGroup, Language } from '../../../exam'
import { ExamInput } from './exam-input/exam-input'
import './select-exams.less'
import { useTranslation } from 'react-i18next'
import { useExamsValidCheck } from '../../../util/validation'
import { ValidationResult } from '@digabi/certification-calculator'
import { useHistory, useLocation } from 'react-router-dom'

interface Props {
  allExams: ExamGroup[]
  language: Language
}

type ResultProps = {
  numberOfExams: number
  validationResult: ValidationResult[] | undefined
  isValidating: boolean
}

function useExamState(groupedExams: ExamGroup[], amountOfExams: number) {
  const getAvailableExams = (selectedExams: Exam[]) => allExams.filter((exam) => !selectedExams.includes(exam))

  const allExams: Exam[] = groupedExams.flatMap(({ exams }) => exams)
  const [selectedExams, setSelectedExams] = useState<Exam[]>([])

  const [examSelections, setExamSelections] = useState<Array<Exam | null>>(new Array(amountOfExams).fill(null))
  const [availableExams, setAvailableExams] = useState(getAvailableExams(selectedExams))
  const history = useHistory()
  const location = useLocation()

  function setExams(newExamSelections: Array<Exam | null>, newSelectedExams: Exam[]) {
    setExamSelections(newExamSelections)
    setSelectedExams(newSelectedExams)
    setAvailableExams(getAvailableExams(newSelectedExams))
  }

  function selectExam(exam: Exam | null, key: number) {
    const newExamSelections = [...examSelections.slice(0, key), exam, ...examSelections.slice(key + 1)]
    const newSelectedExams = newExamSelections.filter((exam) => !!exam) as Exam[]

    setExams(newExamSelections, newSelectedExams)
    history.push(newSelectedExams ? '?' + newSelectedExams.map((exam) => 'exam=' + exam).join('&') : '')
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search).getAll('exam')
    const examsFromQueryParams = (params as Exam[]).slice(0, Math.min(amountOfExams, params.length))
    const fillExams = new Array(amountOfExams - examsFromQueryParams.length).fill(null) as null[]

    setExams([...examsFromQueryParams, ...fillExams], examsFromQueryParams)
  }, [])

  return {
    examSelections,
    selectedExams,
    availableExams,
    selectExam,
  }
}

const Result = ({ numberOfExams, isValidating, validationResult }: ResultProps) => {
  const { t } = useTranslation()

  if (isValidating) {
    return <p>{t('validating-exams')}</p>
  } else if (numberOfExams < 5) {
    return <p>{t('select-exams.please-choose-5-exams')}</p>
  } else if (!validationResult) {
    return null
  } else if (validationResult.length === 0) {
    return <h3>{t(`validationResult.ok`)}</h3>
  } else {
    return (
      <>
        <h3>{t(`validationResult.not-ok`)}:</h3>
        <ul>
          {validationResult.map((vr, index) => (
            <li key={index}>{t(`validationResult.${vr}`)}</li>
          ))}
        </ul>
      </>
    )
  }
}

export function SelectExams({ allExams, language }: Props) {
  const { examSelections, availableExams, selectedExams, selectExam } = useExamState(allExams, 10)
  const { validationResult, isValidating, validateExams } = useExamsValidCheck()
  const history = useHistory()

  useEffect(
    function () {
      if (selectedExams.length >= 5) {
        void validateExams(selectedExams, language)
      }
    },
    [selectedExams]
  )

  const { t } = useTranslation()

  return (
    <div className="select-exams">
      <p className="select_exams__selected-teaching-language">
        {t('selected-language')}: {t('languages.' + language)}
      </p>
      <div className="select-exams__inputs">
        <h3>{t('select-exams.select-exams-to-write')}</h3>

        {examSelections.map((exam, key) => {
          return (
            <ExamInput
              key={key}
              availableExams={availableExams}
              allExams={allExams}
              selectedExam={exam}
              selectExam={(exam: Exam | null) => selectExam(exam, key)}
            />
          )
        })}

        <Result numberOfExams={selectedExams.length} validationResult={validationResult} isValidating={isValidating} />
      </div>
      <div className="select_exams__buttons">
        <button onClick={() => history.push('/')}>{t('back-to-language-selection')}</button>
      </div>
    </div>
  )
}
