import React, { MouseEvent } from 'react'
import './select-teaching-language.less'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

export function SelectTeachingLanguage() {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()

  const choose = (e: MouseEvent<HTMLButtonElement>) => {
    history.push(location.pathname + '/' + e.currentTarget.value)
  }

  return (
    <div className="select-teaching-language">
      <p className="select-teaching-language__ingress">{t('introduction-1')}</p>
      <p className="select-teaching-language__ingress">{t('introduction-2')}</p>
      <p className="select-teaching-language__ingress">{t('introduction-3')}</p>
      <p className="select-teaching-language__ingress">{t('introduction-4')}</p>
      <p
        className="select-teaching-language__ingress select-teaching-language__ingress--small"
        dangerouslySetInnerHTML={{ __html: t('introduction-5') }}
      />
      <h3>{t('choose-language')}</h3>
      <div className="select-teaching-language__languages">
        <button className="select-teaching-language__language" value="fi" onClick={choose}>
          {t('languages.fi')}
        </button>
        <button className="select-teaching-language__language" value="sv" onClick={choose}>
          {t('languages.sv')}
        </button>
      </div>
    </div>
  )
}
