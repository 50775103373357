import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'
import { useParams } from 'react-router-dom'
import { Language } from '../../exam'
import logo from './logo.png'
import name from './name.png'
import './main.less'
import { LanguageSelectButton } from './language-select-button'

export const Main: React.FC = ({ children }) => {
  const { t, i18n } = useTranslation()
  const { language } = useParams<{ language: Language }>()

  useEffect(
    function () {
      void i18n.changeLanguage(language)
    },
    [language]
  )

  return (
    <div className="main">
      <Helmet>
        <html lang={i18n.language} />
        <title>{t('header')}</title>
      </Helmet>
      <div className="main__container">
        <div className="main__content">
          <h1>{t('header')}</h1>
          <div className="main__select-language-bar">
            <LanguageSelectButton language={'fi'} />
            <LanguageSelectButton language={'sv'} />
          </div>
          {children}
        </div>
      </div>
      <div className="main__footer">
        <div className="main__footer-content">
          <div className="main__logo">
            <img className="main__logo-image" alt="" role="presentation" src={logo} />
          </div>
          <p className="main__footer-disclamer">{t('footer-disclamer')}</p>
        </div>
      </div>
    </div>
  )
}
