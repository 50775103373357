import { Exam, Language } from '../exam'
import { useCallback, useState } from 'react'
import { ValidationResult } from '@digabi/certification-calculator'

export function useExamsValidCheck() {
  const [validationResult, setValidationResult] = useState<ValidationResult[] | undefined>(undefined)
  const [isValidating, setIsValidating] = useState(false)

  async function validateExams(selectedExams: Exam[], language: Language) {
    setIsValidating(true)
    const validationResult = await willGraduateWithExams(selectedExams, language)

    setValidationResult(validationResult)
    setIsValidating(false)
  }

  return { validationResult, isValidating, validateExams }
}

async function willGraduateWithExams(selectedExams: Exam[], teachingLanguage: Language): Promise<ValidationResult[]> {
  const query = selectedExams.map((exam) => 'exams=' + exam).join('&')
  const response = await fetch(`/api/v2/validate?teachingLanguage=${teachingLanguage}&${query}`)
  const { validationResult } = (await response.json()) as { validationResult: ValidationResult[] }
  return validationResult
}
