import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { SelectTeachingLanguage } from '../pages/select-teaching-language/select-teaching-language'
import { ExamsByLanguage } from '../pages/select-exams/exams-by-language'
import './exam-wizard.less'
import { useTranslation } from 'react-i18next'
import { existingLanguages } from '../i18n'
import { Main } from '../main/main'

function LanguageRedirector() {
  const { i18n } = useTranslation()
  const language = existingLanguages.includes(i18n.language) ? i18n.language : 'fi'

  return <Redirect to={'/' + language} />
}

export const ExamWizard = React.memo(function ExamWizard() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/:language" exact={true}>
          <Main>
            <SelectTeachingLanguage />
          </Main>
        </Route>
        <Route path="/:language/:teachinglanguage" exact={true}>
          <Main>
            <ExamsByLanguage />
          </Main>
        </Route>
        <Route>
          <LanguageRedirector />
        </Route>
      </Switch>
    </BrowserRouter>
  )
})
