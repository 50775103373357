import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import fi from '../translations/fi-FI.json'
import sv from '../translations/sv-SV.json'
import LanguageDetector from 'i18next-browser-languagedetector'

const resources = {
  fi: {
    translation: fi,
  },
  sv: {
    translation: sv,
  },
}

export const existingLanguages = Object.keys(resources)

export default i18n
  .use(LanguageDetector) // passes i18n down to react-i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'fi',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      transSupportBasicHtmlNodes: true,
    },
  })
