import React, { useEffect, useState } from 'react'
import { disallowedExamsForLanguage, ExamGroup, examsByGroup, Language } from '../../../exam'
import { useParams } from 'react-router-dom'
import { SelectExams } from './select-exams'

function readExams(language: Language) {
  return examsByGroup.map(({ code, exams }) => ({
    code,
    exams: exams.filter((exam) => !disallowedExamsForLanguage[language].includes(exam)),
  }))
}

export function ExamsByLanguage() {
  const { teachinglanguage } = useParams<{ teachinglanguage: Language }>()
  const [allExams, setAllExams] = useState<ExamGroup[]>(readExams(teachinglanguage))

  useEffect(
    function () {
      setAllExams(readExams(teachinglanguage))
    },
    [teachinglanguage]
  )

  return <SelectExams allExams={allExams} language={teachinglanguage} />
}
