import React from 'react'
import ReactDOM from 'react-dom'
import { ExamWizard } from './components/exam-wizard/exam-wizard'
import i18n from './components/i18n'
import './style.less'

// eslint-disable-next-line promise/always-return
void i18n.then(function (): void {
  // eslint-disable-next-line react/no-deprecated
  ReactDOM.render(<ExamWizard />, document.getElementById('root'))
})
