import React, { ChangeEvent, useState } from 'react'
import { Exam, ExamGroup } from '../../../../exam'
import { useTranslation } from 'react-i18next'
import './exam-input.less'

interface Props {
  selectedExam: Exam | null
  availableExams: Exam[]
  allExams: ExamGroup[]
  selectExam: (exam: Exam | null) => void
}

export function ExamInput({ allExams, availableExams, selectedExam, selectExam }: Props) {
  const { t, i18n } = useTranslation()
  const change = (e: ChangeEvent<HTMLSelectElement>) => selectExam((e.target.value as Exam) || null)

  return (
    <select value={selectedExam || undefined} onChange={change} className="exam-input" lang={i18n.language}>
      <option value="" lang={i18n.language}>
        {t('select-exam')}
      </option>
      {allExams
        .map((group) => {
          const exams = group.exams.filter((exam) => availableExams.includes(exam) || selectedExam === exam)

          return { ...group, exams }
        })
        .filter(({ exams }) => exams.length > 0)
        .map((group) => (
          <optgroup label={t('exam-groups.' + group.code)} key={group.code} lang={i18n.language}>
            {group.exams.map((exam) => (
              <option key={exam} value={exam} lang={i18n.language}>
                {t('exams.' + exam)}
              </option>
            ))}
          </optgroup>
        ))}
    </select>
  )
}
